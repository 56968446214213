


.duma{
	.multiMixin(#006DAD);	
}

.megamenu{
	.multiMixin(#638E08);	
}



.multiMixin(@background;)  {

@media (min-width: @grid-float-breakpoint-max) {

	& > a ,
	&.active > a {
   	 background: @background !important;
	} 

	&.dir .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
		background-color:@background;
	}

	& .side-nav h2{
		background-color:@background;
	}

/*
	& .side-nav  .nav-list > .active > a {
		background-color:@background;

	}	

	& .side-nav > .nav-list > li > a{
		background-color:@background;
	}
*/

	& .side-nav-collapse .nav-list li a{
		background-color:@background;

	}

	& .side-nav-collapse .nav-list li a:hover{
		background-color:  lighten( @background, 8%)  ;

	}

	& .side-nav-collapse .nav-list li.selectedPage a{
		background-color:  darken( @background, 8%);

	}

	& h1{
		color:@background;
		
	}
}	
	
}