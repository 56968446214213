.image-center{
  text-align: center;
}

.image-center img{
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.img-block h2{
  margin: 0px;
  padding: 20px 0px 6px;
  font-size: 1em;
  text-decoration: none;
  background-image: none;
  font-weight: bold;
}

.img-block h2, .img-block h2 a{
  color: @text-color;
}