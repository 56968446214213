
.owl-slider-003 .item{

    background-color: #fff;
    background-position: center 55%;
    background-size: cover;
    height: 600px;
    width: 100%;

}

.owl-slider-003 .owl-controls .owl-nav div.owl-prev, .owl-slider-003 .owl-controls .owl-nav div.owl-next {
	color: #fff;
	display: inline-block;
	font-size: 38px;
	height: 76px;
	margin: auto;
	position: absolute;
	left: 0px;
	bottom: 140px;
	transition: all 200ms ease-out 0s;
	width: 45px;
	background-color: rgba(0, 0, 0, 0.95);
	padding: 0px;
	padding-top: 9px;
}

.owl-slider-003 .owl-controls .owl-nav div.owl-next {
	left:46px;
}

.owl-slider-003 .owl-caption span{
	display: block;
	font-size: 28px;
	line-height: 30px;
}

.owl-slider-003 .owl-caption{
	text-align: right;
	display: inline-block;
	font-size: 14px;
	line-height: 16px;
	height: 76px;
	margin: auto;
	position: absolute;
	left: 93px;
	bottom: 140px;
	transition: all 200ms ease-out 0s;
	background-color: rgba(0, 0, 0, 0.60);
	padding: 16px 15px 0px 45px;
	font-weight: bold;
	width: 250px;
}

.owl-slider-003 .owl-caption a, .owl-slider-003 .owl-caption {
	color: white;
}

.owl-theme .owl-controls {
    margin-top: 0px;
}


