
.owl-slider-002 .item{

    background-color: #fff;
    background-position: center 55%;
    background-size: cover;
    height: @full-slider-height;
    width: 100%;

}

.owl-slider-002 .owl-controls .owl-nav div.owl-prev, .owl-slider-002 .owl-controls .owl-nav div.owl-next {
	color: #fff;
	display: inline-block;
	font-size: 42px;
	height: 55px;
	margin: auto;
	opacity: 0.1;
	position: absolute;
	top: (@full-slider-height/2)-40;
	transition: all 200ms ease-out 0s;
	width: 55px;
	background-color: transparent;
}


.owl-slider-002 .owl-controls .owl-nav .owl-prev {
    left: 45px;
}

.owl-slider-002 .owl-controls .owl-nav .owl-next {
    right: 45px;
}

.owl-slider-002:hover .owl-controls .owl-nav .owl-prev {
    opacity: 0.7;
    left: 20px;
}

.owl-slider-002:hover .owl-controls .owl-nav .owl-next {
    opacity: 0.7;
    right: 20px;
}

.owl-slider-002 .slider-outer{
    left: 0;
    position: absolute;
	top: (@full-slider-height/2)-40;
    width: 100%;
    z-index: 10;
    	color: white;

}

.owl-slider-002 .owl-caption{
	font-size: 58px;
}

@media (max-width: @screen-xs-min) {

	.owl-slider-002 .owl-controls{
		display: none;
	}

	.owl-slider-002 .item{

	    height: @xs-slider-height;

	}

	.owl-slider-002 .owl-caption{
		font-size: 28px;
	}

	.owl-slider-002 .slider-outer{
		top: (@xs-slider-height/2)-50;
		
	}

	

}

