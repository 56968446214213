
@box-color-enh: @my-brand-primary;

.box-1 h2 {
	position: relative;
	z-index: 1;
	text-transform: uppercase;
	margin-bottom: 15px;
	font-size: 12px;
	display: block;
	background-color: transparent;
}

.box-1 h2 span { padding: 4px 8px; background-color: @box-color-enh; color: white; font-weight: bold; border-right-color: white; border-right-style: solid; border-right-width: 6px; }
.box-1 h2:before { border-top: 2px solid #dfdfdf; content: ""; margin: 0 auto; position: absolute; top: 50%; left: 0; right: 0; bottom: 0; width: 95%; z-index: -1; }

//
.box-2 h2{ text-transform: uppercase; font-size: 12px; }
.box-2 h2 span{ border-bottom: 5px solid white; border-right-color: white; padding: 4px 8px; background-color: @box-color-enh; color: white; font-weight: bold; border-right-style: solid; border-right-width: 6px; }
.box-2 { background-color: #EFEFEF; padding-bottom: 10px; margin-bottom: 25px; }
.box-2 .row{ padding-top: 10px; }
.box-2 .m-holder{padding: 10px;}
//

.box-3.m-box{ background-color: #EFEFEF; border: none; padding: 0;}
.box-3 .m-holder{ padding: 10px; }
.box-3 h2{ border: none; margin-bottom: 0; font-size: 12px;}
.box-3 h2 span{ padding: 4px 8px; background-color: @box-color-enh; color: white; font-weight: bold; border-right-style: solid; border-right-width: 6px; border-bottom: 5px solid white; }


.box-4{ margin-top: 0; border: 1px solid #dfdfdf; padding: 10px; margin-bottom: 30px; }
.box-4 h2{ margin: 0 0 10px 0; font-size: 12px; border-bottom: 1px dotted #c0c0c0; padding-bottom: 6px; font-weight: bolder; text-transform: uppercase; }
