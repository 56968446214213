
.form-control-intl-tel-input{

  .intl-tel-input {
    display: table-cell;
  }
  .intl-tel-input .selected-flag {
    z-index: 4;
  }
  .intl-tel-input .country-list {
    z-index: 5;
  }
  .input-group .intl-tel-input .form-control {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
  }



  #error-msg {
    color: red;
  }
  #valid-msg {
    color: #00C900;
  }
  input.error {
    border: 1px solid #FF7C7C;
  }
  .hide {
    display: none;
  }

}

