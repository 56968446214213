
@side-nav-title-bg: @my-navbar-inverse-bg;
@side-nav-title-color: @my-navbar-inverse-link-color;


@side-nav-bg: @my-navbar-inverse-bg;
@side-nav-color: @my-navbar-default-link-color;

.sidenav-v1 {
	margin-bottom: 25px;
	padding-bottom: 10px;

}

.sidenav-v1 h2{
	margin-top: 0px;
	background-color: @mySoftBackgroundColor;
	padding: 10px 15px;
	font-size: 15px;
	border: 1px solid  darken(@mySoftBackgroundColor, 5%) ;
	color: @text-color;
}


.sidenav-v1 ul{
		padding: 0px 10px 0px 10px;
		margin: 0px 5px;
}

.sidenav-v1 a{
	color: @text-color;
}

.sidenav-v1 li a:hover{
	color: @my-link-color;
	background-color: transparent;
}

.sidenav-v1  li.active  a,.sidenav-v1  li.active  a:hover{
	background-color: transparent;
	color: @text-color;
	font-weight: bold;
}

.sidenav-v1  li.active a:hover{
	color: @my-link-color;

}