.define-if-not-defined() {


    @schedule-border-color: @my-brand-primary;
    @schedule-weekday-color: lighten(@my-brand-primary, 60%);
    @schedule-day-color: lighten(@my-brand-primary, 70%);
    @schedule-event-color: @my-brand-primary;


}




.schedule {
    width:100%;
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:transparent;
    border-collapse:separate;
    border-left-width:2px;
    border-left-style:solid;
    border-left-color:@schedule-border-color;
    border-top-width:1px;
    border-top-style:solid;
    border-top-color:@schedule-border-color;
    border-bottom-width:1px;
    border-bottom-style:solid;
    border-bottom-color:@schedule-border-color;
    table-layout:fixed;
    margin:0 auto;
}

.schedule .week-day {
    background-image:initial;
    background-position-x:initial;
    background-position-y:initial;
    background-size:initial;
    background-repeat-x:initial;
    background-repeat-y:initial;
    background-attachment:initial;
    background-origin:initial;
    background-clip:initial;
    background-color:@schedule-weekday-color;
    width:50px;
    color: @schedule-border-color;
}

.schedule .week-day span {
    display:block;
}

.schedule .week-day-outer {
    text-align:center;
}

.schedule td {
    border-bottom-width:1px;
    border-bottom-style:solid;
    border-bottom-color:@schedule-border-color;
    border-top-width:1px;
    border-top-style:solid;
    border-top-color:@schedule-border-color;
    border-right-width:1px;
    border-right-style:solid;
    border-right-color:@schedule-border-color;
    background-color:#FFF;
    //width:1.19048%;
    height:60px;
    position:relative;
    opacity:1;
}


.schedule th {
    white-space:nowrap;
    font-weight:bold;
    font-size:12px;
    text-align:center;
    border-right-width:2px;
    border-right-style:solid;
    border-right-color:@schedule-border-color;
    border-bottom-width:1px;
    border-bottom-style:solid;
    border-bottom-color:@schedule-border-color;
    border-top-width:1px;
    border-top-style:solid;
    border-top-color:@schedule-border-color;
    padding:3px;
    background-color: @schedule-day-color;
    color:@schedule-border-color;

}

.schedule tr td:last-of-type {
    border-right-width:2px;
    border-right-style:solid;
    border-right-color:@schedule-border-color;
}

.schedule tr:first-of-type th {
    border-top-width:1px;
    border-top-style:solid;
    border-top-color:@schedule-border-color;
}


.schedule .event {
    background-color:@schedule-event-color;
    position:absolute;
    left:0;
    top:0;
    height:100%;
    z-index:2;
    opacity:0.9;
    color: white;

}

.schedule .event span {
    opacity:1;
    //text-align:center;
    display:block;
    //white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    vertical-align:middle;
    font-size: 12px;
}




.schedule .day-number {
    color:@schedule-border-color;
    font-size:12px;
    line-height:16px;
}

.schedule .day-name {
    color:@schedule-border-color;
    font-size:30px;
    line-height:34px;
}


.schedule .events-add td a{
    text-decoration: none;
    color: orange;
}

.schedule .events-add td{
    height: 25px;
    font-size: 14px;
    text-align: center;
}

.schedule .events-add td{
    height: 25px;
    font-size: 14px;
    text-align: center;
}

.schedule .add-link{
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #ededed;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    font-family: Arial;
    padding-top: 5px;
}

.schedule .add-link i{
    top:4px;
    left: 50%;
    position: absolute;
    margin-left: -5px;
}


.schedule .add-link:hover{
    background-color: #dfdfdf;
}