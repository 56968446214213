
@olo-cal-date-width: 42px;
@olo-cal-month-width: 7 * @olo-cal-date-width + 42px;

.olo-cal{

	div#calendar{
		margin:0px auto;
		padding:0px;
		width: @olo-cal-month-width;
	}

	div#calendar div.box{
		position:relative;
		top:0px;
		left:0px;
		width:100%;
		height:40px;
		background-color: 	#787878 ;
	}

	div#calendar div.olo-header{
		color: white;
		line-height:40px;
		vertical-align:middle;
		position:absolute;
		left:0px;
		top:0px;
		width:@olo-cal-month-width;
		height:40px;
		text-align:center;
	}

	div#calendar div.header a.prev,div#calendar div.header a.next{
		position:absolute;
		top:0px;
		height: 17px;
		display:block;
		cursor:pointer;
		text-decoration:none;
		color:#FFF;
	}

	div#calendar div.header span.title{
		color:#FFF;
		font-size:16px;
	}


	div#calendar div.header a.prev{
		left:0px;
	}

	div#calendar div.header a.next{
		right:0px;
	}




	/*******************************Calendar Content Cells*********************************/
	div#calendar div.box-content{
		border:1px solid #787878 ;
		border-top:none;
		min-height: 270px;

	}



	div#calendar ul.label{
		float:left;
		margin: 0px;
		padding: 0px;
		margin-top:5px;
		margin-left: 5px;
	}

	div#calendar ul.label li{
		margin:0px;
		padding:0px;
		margin-right:5px;
		float:left;
		list-style-type:none;
		width:@olo-cal-date-width;
		height:40px;
		line-height:40px;
		vertical-align:middle;
		text-align:center;
		color:#000;
		font-size: 15px;
		background-color: transparent;
	}


	div#calendar ul.dates{
		float:left;
		margin: 0px;
		padding: 0px;
		margin-left: 5px;
		margin-bottom: 5px;
	}

	/** overall width = width+padding-right**/
	div#calendar ul.dates li{
		margin:0px;
		padding:0px;
		margin-right:5px;
		margin-top: 5px;
		line-height:30px;
		vertical-align:middle;
		float:left;
		list-style-type:none;
		width:@olo-cal-date-width;
		min-height:30px;
		font-size:18px;
		//background-color: #fafafa;
		color:#000;
		text-align:center;
		font-family: "Fira Sans", Arial, sans-serif;
	}




	.oloCheckBox{
		padding: 2px;
		float: left;
	}


	div#calendar ul.dates li.mask{
		background-color: transparent;
	}

	.oloColor{
		width: 30px;
		height: 24px;
		display: block;
		float: left;
	}

	.o-date-outer{
		position: relative;
	text-align: center;
		padding-top: 8px;
	}
	.o-i-o{
		position: absolute;
		top:6px;
		z-index: 1;
	}
	.date-caption{
		height: 30px;
		display: block;
		z-index: 2;
		position: absolute;
		left:23%;
		top:45%;
	}

	.l1{
		margin-left: 6px;
	}

}


.badge-olo{
	width: 16px;
	height: 16px;
	margin-right: 4px;
	border: 1px solid #b0bec5;
}
.olo-t{
	margin-right: 4px;
}

.section-olo{
	h2{
		background-color: @my-brand-primary;
		color: white;
		padding: 15px;
	}
	.naturpack{
		margin-top: 20px;
		margin-left: 20px;
		margin-bottom: 20px;
	}
	padding-bottom: 50px;
}

