
.tgr-hero{
  position: relative;
}

.tgr-hero, .tgr-hero a, .tgr-hero h2 {
  color: #fff;
}


.tgr-box {
  display: table;
  width: 100%;
}
.tgr-box--fixed {
  table-layout: fixed;
}
.tgr-box--stretched {
  height: 100%;
}
.tgr-box__magnet {
  display: table-cell;
  float: none;
  height: 100%;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  vertical-align: middle;
}
.tgr-box__magnet--sm-padding {
  padding: 41px 0;
}
.tgr-box__magnet--top-left,
.tgr-box__magnet--top-center,
.tgr-box__magnet--top-right {
  vertical-align: top;
}
.tgr-box__magnet--bottom-left,
.tgr-box__magnet--bottom-center,
.tgr-box__magnet--bottom-right {
  vertical-align: bottom;
}
.tgr-box__magnet--top-left,
.tgr-box__magnet--center-left,
.tgr-box__magnet--bottom-left {
  text-align: left;
}
.tgr-box__magnet--top-right,
.tgr-box__magnet--center-right,
.tgr-box__magnet--bottom-right {
  text-align: right;
}

.tgr-section--bg-adapted {
  background-attachment: scroll;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.tgr-section--bg-not-adapted {
  background-attachment: scroll;
  background-position: 50% 0;
  background-repeat: no-repeat;
}

.tgr-section__container {
  padding: 0;
  position: relative;
  z-index: 3;
}


.tgr-hero__text, .tgr-hero__subtext{
  padding: 0px;
  margin: 0px;
}


@media screen and (min-width: @screen-sm-min) {
  .tgr-hero__text {
    font-size: (@font-size-base * 3);
    line-height: (@font-size-base * 3 + 5 );
    font-weight: bold;
  }

  .tgr-hero__subtext {
    font-size: (@font-size-base * 1.2);
    line-height: (@font-size-base * 1.5 + 5 );
    font-weight: 600;
  }

  .tgr-hero__btn{
    margin-top: 15px;
    font-size: (@font-size-base * 1.2);

  }

}

@media screen and (max-width: @screen-sm-min) {
  .tgr-hero__text {
    font-size: (@font-size-base * 1.6);
    line-height: (@font-size-base * 1.6 + 5 );
    font-weight: bold;

  }

  .tgr-hero__subtext {
    font-size: (@font-size-base * 1.1);
    line-height: (@font-size-base * 1.1 + 5 );
  }

  .tgr-hero__btn{
    margin-top: 10px;
    font-size: (@font-size-base * 1.1);

  }

}

.tgr-hero-overlay {
  background: #222;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  opacity: 0.25;
}

.tgr-hero-container{
  z-index: 3;
  position: relative;
}

.tgr-background-video, .tgr-background-video-preview {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}