@import "./tangram-box.less";
@import "./gdpr-cookie.less";
@import "./buttons-section.less";
@import "./calendar.less";
@import "./rdb.less";
@import "./tgr.image.less";
@import "./tgr.utility.less";
@import "../../schedule/schedule.less";
@import "./tgr.shop.less";
@import "./tgr.cards.less";
@import "./tgr.search.less";
@import "./tgr.phone.less";
@import "./tgr.attributes.less";
@import "../../olo/olo.less";
@import "./button-more.less";





.list-inline-block a {
  display: inline-block;

}

.img-bordered {
  outline: 1px solid darken(@mySoftBackgroundColor, 15%);
  background-color: #FFF;
  padding: 3px;
  display: inline-block;
  margin-bottom: 8px;
}

.pull-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.list-vertical {
  .list-unstyled();

  > li {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.list-vertical .nav-header {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 4px;
  padding-left: 0px;
  padding-right: 0px;

}

.nav-header h2 {
  font-size: 16px;
  font-weight: bold;
  padding: 0px 0px 10px 0px;
  margin: 0px;
}

.list-vertical address {
  margin-bottom: 0px;

}

.list-vertical li [class^="icon-"] {
  margin-right: 8px;
}

.navbar-inverse .navbar-toggle.btn-menu {
  border-color: #333333;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 1.1em;
  padding: 8px 10px;

}

.articles-list-generic [class*="acol-"] {
  margin-top: 0px;
  margin-bottom: 40px;
}

.articles-list-generic [class*="acol-"] .media {
  margin-bottom: 0px;

}

.video-list-thumbs .icon-play-circle {
  opacity: 0.6;
  position: absolute;
  text-shadow: 0 1px 3px rgba(0, 0, 0, .5);
  color: white;
  right: 40%;
  top: 30%;
  font-size: 5em;
}

.video-list-thumbs .icon-play-circle.i-thumbail {
  right: 35%;
  top: 25%;
  font-size: 2.5em;
}

.video-list-thumbs .icon-play-circle.i-tiny {
  right: 35%;
  top: 30%;
  font-size: 4em;
}

.video-list-thumbs .video-outer {

  display: inline-block;
  color: #fff;
  position: relative;
}

.video-list-thumbs .video-outer a:hover {
  background-color: #000;
  transition: all 500ms ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
  text-decoration: none
}

.video-list-thumbs .video-outer a:hover .icon-play-circle {
  color: #fff;
  opacity: 1;
  text-shadow: 0 1px 3px rgba(0, 0, 0, .8);
  transition: all 500ms ease;
}

.video-list-thumbs a .icon-play-circle {
  color: white;
}

//

.thumbnail-left > img, .thumbnail-left a > img {
  margin-left: 0;
  margin-right: 0;
}

.aligncenter {

  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

}

//https://gist.github.com/grevory/5369438
.thumbnail.with-caption {
  display: inline-block;
  background: #f5f5f5;
}

.thumbnail.with-caption p {
  margin: 0;
  padding-top: 0.5em;
  font-size: 90%;
}

.float_left {
  float: left;
  display: inline;
  margin-right: 1em;
  margin-bottom: 0.15em;
}

.float_right {
  float: right;
  display: inline;
  margin-left: 1em;
  margin-bottom: 0.15em;
}

//articles start

.media-img {
  margin-bottom: 8px;
}

.posts-mini .dl-horizontal {
  margin-bottom: 15px;
  overflow: hidden;
}

.posts-mini .dl-horizontal dt {
  float: left;
  width: 52px;
}

.posts-mini .dl-horizontal dt img {
  border: 1px solid #DDDDDD;
  height: 52px;
  margin-top: 2px;
  padding: 2px;
  width: 52px;
}

.posts-mini .dl-horizontal dd {
  margin-left: 60px;
}

.magazine-mini-news h3 {
  //font-size: @font-size-base;
  //line-height: @line-height-base;
  margin-bottom: 6px;
  margin-top: 0px;

}

//articles end

.thumbnail-style .caption {
  padding: 0px;

}

.caption h2 {
  font-size: @font-size-base;
  margin: 0px;
  padding: 10px;
  background-color: @mySoftBackgroundColor;

}

.caption h2 a {
  color: white;

}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.separator {
  padding: 0px;
  margin: 15px 0px 15px;
  border-top: 1px solid darken(@mySoftBackgroundColor, 10%);

}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

h2.block-title-plain {
  font-size: @font-size-large;
  font-weight: bold;
  margin: 0px;
  padding: 10px 0;
  position: relative;
  text-transform: uppercase;
}

h2.block-title {
  border-bottom: 1px solid @gray-light;
  font-size: @font-size-large;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 10px 0;
  position: relative;
  text-transform: uppercase;
}

.error-message, .error-block {
  width: auto;
  display: block;
  line-height: 10.688pt;
  font: bold 0.95em/10.688pt arial;
  min-height: 15px;
  font-weight: 700;
  color: #EE350B;
  margin: 3px 0px 0px 0px;
  padding: 1px;
  padding-left: 15px;
  background: url(/images/error_bg.gif) no-repeat 2px 2px;
}



.carousel-text-caption {
  color: inherit;
  position: inherit;
  padding-bottom: 0px;

}

.row-no-padding {
  margin-left: 0;
  margin-right: 0;
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.reset {
  margin: 0px;
  padding: 0px;
}

a.color_pick {
  border: 1px solid #EFEFEF;
  height: 15px;
  overflow: hidden;
  cursor: pointer;
  float: left;
  margin: 0 0.5em 5px 0;
  width: 30px;
}

.product-detail a.color_pick {

  border: 1px solid #EFEFEF;
  height: 20px;
  overflow: hidden;
  cursor: pointer;
  float: left;
  margin: 0 0.5em 5px 0;
  width: 30px;
}

ul.link-box li span {
  font-weight: bold;
}

ul.file-box {
  margin: 0px;
  padding: 0px;
}

.icon {
  background-position: 0 0.1em;
  background-repeat: no-repeat;
  padding-left: 20px;
  list-style-position: outside;
  list-style-type: none;
  padding-bottom: 10px;
}

.txt {
  background-image: url("/images/file-txt.gif") !important;
}

.xls, .xlsx, .ods {
  background-image: url("/images/file-xls.gif") !important;
}

.zip {
  background-image: url("/images/file-zip.gif") !important;
}

.avi {
  background-image: url("/images/file-avi.gif") !important;
}

.wmv {
  background-image: url("/images/file-avi.gif") !important;
}

.ppt, .pptx {
  background-image: url("/images/file-ppt.gif") !important;
}

.pdf {
  background-image: url("/images/file-pdf.gif") !important;
}

.mp3 {
  background-image: url("/images/file-mp3.gif") !important;
}

.exe {
  background-image: url("/images/file-exe.gif") !important;
}

.jpg {
  background-image: url("/images/file-jpg.gif") !important;
}

.vcf {
  background-image: url("/images/file-vcf.gif") !important;
}

.kmz {
  background-image: url("/images/file-kmz.gif") !important;
}

.rtf, .doc, .docx, .odt {
  background-image: url("/images/file-doc.gif") !important;
}

.odt {
  background-image: url("/images/file-odt.gif") !important;
}


.link-more-records {
  margin-top: 5px;
}

.a-video-outer {
  position: relative;
  display: table-cell;
}

.a-video-outer .icon-play-circle {
  opacity: 0.9;
  position: absolute;
  color: white;
  bottom: 0px;
  right: 4px;
  font-size: 2em;
}

.media-list-1 .media-outer {
  margin-top: 15px;
}

.media-list-1 .media-outer.first {
  margin-top: 0px;
}

.picture-heading {
  margin-top: 10px;
  margin-bottom: 10px;
}

.a2-video-outer {
  position: relative;
  display: block;
  overflow: hidden;
}

.a2-video-inner {

  position: absolute;
  bottom: 0px;
  left: 0px;

}

.play-text {
  height: 30px;
  line-height: 30px;
  width: 140px;
  color: white;
  background-color: #ce1000;
  display: block;
  position: absolute;
  bottom: 0px;
  left: -140px;
  padding: 0px 0px 0px 6px;
  text-transform: uppercase;

}

.play-text, .a2-video-outer .icon-play {
  transition-duration: .5s;

}

.a2-video-outer:hover .play-text {
  left: 0;
}

.a2-video-outer:hover .icon-play {
  left: 140px;
}

.a2-video-outer .icon-play {
  opacity: 1;
  font-size: 1.2em;
  color: white;
  background-color: #ce1000;
  padding-left: 8px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.date-month, .date-day, .date-year {
  width: auto;
  display: inline;

}

.control-label {
  display: block;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}

.mfp-close-btn-in {
  color: #333333;
}

/* no more*/

@media only screen and (max-width: 800px) {

  /* Force table to not be like tables anymore */
  .no-more-tables table,
  .no-more-tables thead,
  .no-more-tables tbody,
  .no-more-tables th,
  .no-more-tables td,
  .no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .no-more-tables tr {
    border: 1px solid #ccc;
  }

  .no-more-tables .table > thead > tr > th,
  .no-more-tables .table > tbody > tr > th,
  .no-more-tables .table > tfoot > tr > th,
  .no-more-tables .table > thead > tr > td,
  .no-more-tables .table > tbody > tr > td,
  .no-more-tables .table > tfoot > tr > td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  .no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  /*
  Label the data
  */
  .no-more-tables td:before {
    //here fix w3c
    		content: attr(data-title);
  }

}

/* end no-more*/

/* fix obj. formular udaje checkbox */
.e-comm .checkbox input[type="checkbox"] {
  margin-left: 0px;
  position: static;
  float: left;
  margin-right: 5px;
}

/* forms  */


/**/
@media (min-width: @screen-lg-min) {
  .pull-lg-none {
    float: none !important;
  }

}

@media (min-width: @screen-md-min) {
  .pull-md-none {
    float: none !important;
  }

}

@media (min-width: @screen-sm-min) {
  .pull-sm-none {
    float: none !important;
  }

}

@media (max-width: @screen-xs-min) {
  .pull-xs-none {
    float: none !important;
  }

}

.m-a-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-border {
  border: none;
}

.read-more {
  float: right;
  margin-top: 15px;
  text-decoration: underline;
  text-transform: lowercase;
}

.profiler {
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: red;
  color: #fff;
  padding: 10px;
}

.modal.modal-center {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal.modal-center:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal.modal-center .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal .modal-close {
  padding: 15px;
  min-height: 16.5px;
}

i.icon-md {
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
}

.rounded-x {
  border-radius: 50% !important;
  display: inline-block !important;
  text-align: center;
  background: @my-brand-primary;
  color: white;
  margin-right: 5px;
}

.rounded-x.icon-facebook {
  background-color: #3b5998;
}

.rounded-x.icon-google-plus {
  background-color: #db402c;
}

.rounded-x.icon-youtube {
  background-color: #cb2027;
}

.cookie-disclaimer {
  background: #000000;
  color: #FFF;
  opacity: 0.75;
  width: 100%;
  bottom: 0px;
  left: 0;
  z-index: 999999999;
  position: fixed;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.cookie-disclaimer a {
  color: white;
  text-decoration: underline;
}

.cookie-disclaimer .container {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
}

.cookie-disclaimer .accept-cookie {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

//same - height

//.row-same-height [class*="col-"]{
//	margin-bottom: -99999px;
//	padding-bottom: 99999px;
//}
//
//.row-same-height{
//	overflow: hidden;
//}
//
//.row-same-height .box{
//	margin-bottom: -99999px;
//	padding-bottom: 99999px;
//}

.date-outer {
  text-align: center;
  float: left;
  width: 80px;
  color: #333;
  padding: 0px 6px 6px 0px;
  background-color: white;
  margin-right: 15px;
}

.date-outer .month {
  background-color: @my-brand-primary;
  color: white;
  display: block;
  font-size: 0.8em;
}

.date-outer .day {
  display: block;
  //font-size: 28px;
  //font-family: 'PT Sans Narrow', sans-serif;
  background-color: @mySoftBackgroundColor;

}

.date-outer .day .day-from {
  margin-right: 2px;
  text-transform: lowercase;
}

.filter-h .btn-filter::after {
  font-family: FontAwesome;
  content: "\f067";
  display: inline-block;
  padding-right: 5px;
  vertical-align: middle;
  margin-left: 3px;
}

.filter-h .btn-filter[aria-expanded="true"]::after {
  content: "\f068";
}

.last-info-content {
  //padding: 10px 0px;
  //border-bottom: 1px solid @mySoftBackgroundColormuted;
  font-size: @font-size-small;
  margin-bottom: 10px;
  //margin-top: 50px;
  //color: @text-muted;
  //text-transform: uppercase;
}

.last-info-content {
  background-color: #fafafa;
  padding: 25px;
  margin: 50px 0px 30px;
}

.soc-share li.share-facebook img {
  background-color: #3B5998;
  border-radius: 50%;
}

.soc-share li.share-facebook img {
  background-color: #3B5998;
  border-radius: 50%;
}

.soc-share li.share-twitter img {
  background-color: #1DA1F2;;
  border-radius: 50%;
}

.soc-share li * {
  vertical-align: middle;
}

.list-inline.soc-share li {
  padding-left: 0px;
}

.soc-share li span {
  padding-left: 5px;
  padding-right: 1em;
}

.mbr-gallery-row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mbr-gallery-item {
  margin-bottom: 30px;
}



.issuu-embed-container {
  position: relative;
  padding-bottom: 56.25%; /* set the aspect ratio here as (height / width) * 100% */
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.issuu-embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.no_underine, .no_underine:hover {
    text-decoration: none;
}


.lang-lbl{
  margin-right: 8px;
}

.lang-lbl img{
  margin-right: 4px;
}


.img-motive{
  background-size: auto auto;
  height: 300px;
  background-size: cover;
  background-position: center center;
}


@media screen and (max-width: 768px) {
  picture.img-responsive img{
    display: block;
    max-width: 100%;
    height: auto;
  }

}


.modal-dialog .modal-content{
  color: black;
  a{
    color: black;

  }

  h1,h2,h3,h4,h5,h6{
    color: black;
  }
}

@wheather-cal-bg: #fff;
@wheather-cal-color: #333;


.rounded-x.icon-instagram {
    background-color: red;
}


.parallax-window {
  min-height: 400px;
  background: transparent;
  display: flex;
  align-items: center;
  text-align: center;
}


ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 15px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.section-padding-t-b{
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-t-b{
  padding-top: 25px;
  padding-bottom: 25px;
}


.padding-l-r{
  padding-left: 25px;
  padding-right: 25px;
}


.bg-grey{
  background-color: #fafafa;
}

.padding-small{
  padding: 15px;
}

.m-b-small{
  margin-bottom: 25px;
}

.owl-carousel-catalog .item{
  height:400px ;
  .owl-hero-outer{
    padding-top: 50px;
  }
}

.owl-carousel-catalog.owl-carousel .item img{
  width: auto;
  width: 500*0.5px;
  height: 700*0.5px;
  object-fit: cover;
  margin-left: 20px;
  margin-top: 20px;
}

.btn-black.btn-primary:hover,.btn-black.btn-default:hover,.btn-black.btn-default {
  background-color: #171B1E;;
}
@media (min-width: @screen-md-min) {
  .owl-carousel-catalog .item{
    height:auto;
    padding: 25px;
  }
}

@media (min-width: @screen-sm-min) {
  .owl-carousel-catalog .item{
    height:auto;
    padding: 25px;

  }

}

@media (max-width: @screen-xs-min) {
  .owl-carousel-catalog .item{
    height:auto;
    padding: 25px;

  }


}

