
.define-if-not-defined() {
	@my-topbar-default-bg: @mySoftBackgroundColor;
	@my-topbar-default-color: @text-color;
}

.define-if-not-defined();



/* topbar */
.topbar ul{
	margin: 0px;
	list-style: outside none none;
	padding: 0px;
}


.topbar li {
	padding: 10px 8px ;
    display: inline-block;
}


.topbar-inverse{
	background-color: #212021;
	font-size: 13px;	
}

.topbar-inverse, .topbar-inverse a{
	color: #a1a1a1;
}

.topbar{
	background-color: @my-topbar-default-bg;
	font-size: 13px;	
	border-bottom: 1px solid  darken(@my-topbar-default-bg, 5%) ;

}

.topbar, .topbar a{
	color: @my-topbar-default-color;
}