.menu-simple-2-col .flex-container, .menu-simple-2-col .flex-container > div {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.menu-simple-2-col .flex-container {
  margin-bottom: 20px;
}

.menu-simple-2-col .flex-container > div {
  width: 50%;
  /*min-height: 200px;*/
  background: #fff;
  padding: 10px;


  flex-direction: column;
  -webkit-flex-direction: column;

  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;

  text-align: center;
}

.menu-simple-2-col .flex-container > div:first-child {
  margin-right: 20px;
}

.menu-simple-2-col .flex-container > div span{
  display: block;
  text-align: center;
}

.nav-flex-2-col.nav-inverse >div{
  background-color: #0b0b0b;
  color: white;
}

.menu-simple-2-col.block-inverse{
  background-color: #000;
  color: white;

}