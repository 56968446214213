@media (min-width: @grid-float-breakpoint-max) {

	.navbar-justify .navbar-nav {
	        margin: 0 auto;
	        display: table;
	        table-layout: auto;
	        float: none;
	        width: 100%;
	}
	    
	.navbar-justify .navbar-nav>li {
		display: table-cell;
		float: none;
		text-align: center;
	}
	
	.navbar-justify .navbar-collapse{
		padding-left: 0px;
		padding-right: 0px;
	} 


}