.search-form.search-form-v2{
  padding: 3px;
  border: solid 1px #E4E4E4;
  border-radius: 3px;
  background-color: #fff;

  input{
    border: 0;
    box-shadow: none;
    font-size:  ceil((@font-size-base * 1)); // ~18px
    line-height: 1.33 * @font-size-base;
    padding:  8px 13px;

  }

  button{
    margin: 2px 0 0 0;
    background: none;
    box-shadow: none;
    border: 0;
    color: #666666;
    padding: 0 8px 0 10px;
    border-left: solid 1px #ccc;
  }

  button:hover{
    border: 0;
    box-shadow: none;
    border-left: solid 1px #ccc;
  }

  i{
    font-size:  ceil((@font-size-base * 1.4)); // ~18px
    color: @my-brand-primary;
  }

  .form-control:focus{
    -webkit-box-shadow: none;
    box-shadow: none;  }
}
