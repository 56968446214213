//side-nav

@side-nav-title-bg: @my-navbar-inverse-bg;
@side-nav-title-color: @my-navbar-inverse-link-color;


@side-nav-bg: @my-navbar-inverse-bg;
@side-nav-color: @my-navbar-default-link-color;

.side-nav {
	margin-bottom: 25px;
	padding-bottom: 10px;

}

.side-nav h2{
	background-color: @my-sidenav-bg;
	padding: 20px 15px 10px 15px;
	font-size: 18px;
	color: @my-sidenav-color;
	margin: 0px;
	border-bottom: @my-sidenav-border-size solid @my-sidenav-border-color;

}



.side-nav  .nav-list > .active > a {
	background-color: @my-sidenav-bg;
	color: @my-sidenav-color;

}

.side-nav ul{
		padding: 0px 10px 0px 10px;
		margin: 0px 5px;
}



.side-nav > .nav-list > li > a{
	background-color: @my-sidenav-bg;
	color: @my-sidenav-color;
	
}

.side-nav .nav-list .level2 li a {
	background-color:  darken(@my-sidenav-bg, 6.5%) ;
	padding-left: 20px;
}

.column-bordered {
	background-color:  @my-sidenav-border-color;

}
