.side-nav-collapse ul {
	padding-left: 0px;
	padding-right: 0px;
	margin-left: 0px;
	margin-right: 0px;

}


.side-nav-collapse .nav-list li a{
	background-color: @my-sidenav-bg;
	color: @my-sidenav-color;
	border-bottom: @my-sidenav-border-size solid @my-sidenav-border-color;
	padding: 10px 15px;
	margin-left: 0px;
	margin-right: 0px;

}

.side-nav-collapse .nav-list .level2 li a{
	padding-left: 25px;
}

.side-nav-collapse .nav-list .level3 li a{
	padding-left: 35px;
}

.side-nav-collapse .nav-list .level4 li a{
	padding-left: 45px;
}

.side-nav-collapse .nav-list .level5 li a{
	padding-left: 50px;
}


.side-nav-collapse .nav-list li.selectedPage a{
	background-color: darken(@my-sidenav-bg, 10%)
}
