.card{
  border: 0;
  background-color: @mySoftBackgroundColor;
}

.card-body{
  padding: 10px 20px 5px;
}

.card a, .card a:hover{
  text-decoration: none;
  color: @text-color;
}

.card-img-top{
  max-height: 250px;
}


.img-block-caption.card-body h2{
  margin: 0px;
  padding: 0px;
}

.perex-row{
  margin-bottom: 20px;
}