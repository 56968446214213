.owl-stretched {
    height: 100%;
    display: table;
    width: 100%;
}
.owl-hero {
    display: table-cell;
    float: none;
    height: 100%;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    vertical-align: middle;
    z-index: 10;
    color: white;
}

.caption-inverse .owl-hero-outer {
	background-color: fade(@banner-text-caption-bg, 80%);
	color: white;
	padding: 10px 12px;
	display: inline-block;

}


.owl-hero-outer .btn{
    margin-top: 15px;
}

.owl-hero-text {
   font-size: floor((@font-size-base * 2.1));
	line-height: floor(((@font-size-base * 2.1)+5));
}





.owl-hero-text a, .owl-hero-sub-text a{
    color: white;
    text-decoration: none;
}

.owl-hero-sub-text {
   font-size: floor((@font-size-base * 1.5));
	line-height: floor(((@font-size-base * 1.5)+5)); 

}

.owl-hero-small .owl-hero-text{
  font-size: floor((@font-size-base * 2));
	line-height: floor(((@font-size-base * 2)+5)); 
}

.owl-hero-small .owl-hero-sub-text {
  font-size: floor((@font-size-base * 1.3));
	line-height: floor(((@font-size-base * 1.3)+5)); 
}


@media (max-width: @grid-float-breakpoint) {

    .owl-hero-text {
        font-size: floor((@font-size-base * 1.4));
        line-height: floor(((@font-size-base * 1.4)+3));
    }

    .owl-hero-sub-text {
        font-size: floor((@font-size-base * 1));
        line-height: floor(((@font-size-base * 1)+2));

    }

    .owl-hero-small .owl-hero-text{
        font-size: floor((@font-size-base * 1.3));
        line-height: floor(((@font-size-base * 1.3)+2));
    }

    .owl-hero-small .owl-hero-sub-text {
        font-size: floor((@font-size-base * 1));
        line-height: floor(((@font-size-base * 1)+12));
    }




}



.owl-001 .thumbnail {
    padding: 0px;
    border: none;
    margin: 0px 3px;
}
.owl-001 .caption {
    padding: 3px 0px;
}
//
.owl-002 h2 {
    border: none;
    background-color: @gray-lighter;
    color: @gray-darker;
    margin-bottom: 8px;
    padding: 5px;
    font-size: 11px;
    display: inline-block;
    font-weight: normal;
    text-transform: uppercase;
}
.owl-002 .thumbnail {
    padding: 0px;
    border: none;
    margin: 0px 3px;
}
.owl-002 .caption {
    margin-top: 6px;
    padding: 4px;
    background-color: @gray-lighter;
    color: @gray-darker;
    display: inline-block;
    font-size: 12px;
}
.owl-002 .video-list-thumbs .caption {
    margin-top: 0px;
}
.owl-002 .caption a {
    color: @gray-darker;
}
.owl-002 .owl-outer {
    position: relative;
    border: 1px solid @gray-lighter;
    padding: 20px 0px;
    margin-bottom: 20px;
}
.owl-002 .owl-outer .prev {
    position: absolute;
    left: 0px;
    top: 40px;
}
.owl-002 .owl-outer .next {
    position: absolute;
    right: 0px;
    top: 40px;
}
.owl-002 .owl-outer .btn {
    background-color: @brand-primary;
    border: 0 none;
    color: #fff;
    padding: 4px 10px;
    text-decoration: none;
}
.owl-002 .owl-inner {
    margin: 0px 40px;
}
.owl-a-02 .item {
    background-color: @gray-lighter;
}
.owl-a-02 .text-col {
    padding: 20px;
}
/**/

.owl-carousel-inverse a,
.owl-carousel-inverse {
    color: white;
}
.owl-carousel-inverse a:hover {
    text-decoration: none;
}
.owl-carousel-inverse .item {
    background-color: @brand-primary;
}

.owl-layout-full .text-col {
    padding: 10px;

}

.owl-layout-full .text-col h2{
    margin: 0px;
}

.owl-carousel-inverse, .owl-carousel-inverse a{
    color: white;
}

.owl-layout-half .text-col {
    padding: 30px 15px 10px 15px;
}
.owl-carousel-inverse .img-col {
    position: relative;
}


@media (min-width: @grid-float-breakpoint-max) {

    .owl-carousel-inverse .triangle-left {
        border-bottom: 30px solid transparent;
        border-right: 40px solid @brand-primary;
        border-top: 30px solid transparent;
        position: absolute;
        top: 50px;
        width: 100%;
        z-index: 400;
        left: 5px;
    }

    .owl-hero-outer{
        padding-left: 30px;
        padding-right: 30px;
    }

}


/**/

.title-block h2 {
    margin-bottom: 10px;
    font-size: floor((@font-size-base * 1.85));
    font-weight: bold;
    line-height: floor((@font-size-base * 1.85)+4);
    letter-spacing: 0px;

}
.center-nav .owl-nav {
    bottom: auto;
    left: auto;
    position: static;
    right: auto;
    top: auto;
}
.center-nav-animate:hover .owl-next {
    right: 15px;
}
.center-nav-animate .owl-prev {
    left: 45px;
}
.center-nav-animate .owl-prev,
.center-nav-animate .owl-next {
    opacity: 0;
    visibility: hidden;
}
.nav-higher .owl-nav {
    top: -64px;
    font-size: 0;
    position: absolute;
    right: 0;
}
.center-top-nav .owl-nav {
    position: absolute;
    left: 50%;
    margin-left: -38.5px;
    right: auto;
    top: -55px;
}
.nav-center-side .owl-nav div {
    position: absolute;
    top: 30%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #FFF;
    font-size: 18px;
}

.nav-center-side .owl-nav .owl-prev {
    left: 15px;
    right: auto;
    opacity: 0;

}

.nav-center-side:hover .owl-nav .owl-prev {
    opacity: 1;

}

.nav-center-side .owl-nav .owl-next {
    left: auto;
    right: 15px;
    opacity: 0;

}

.nav-center-side:hover .owl-nav .owl-next {
    opacity: 1;

}

.nav-center-side .owl-nav div i {
    font-size: 25px;
}



/* OWL ANIMATION */

.label-carousel {
    display: inline-block;
    font-size: floor((@font-size-base * 0.9));
   
    padding: 5px 0px 0px 0px;
    margin-left: 8px;
}

.owl-dots-top-right .owl-controls .owl-dots {
    position: absolute;
    right: 10px;
    top: 10px;
}

.owl-layout-half .text-col {
    padding-top: 45px;
}

.owl-layout-half .text-col .date{
    color: white;
}

.mbr-stretched{
    background-position: 50% 50%;
    background-size: cover;
}



.owl-carousel .owl-item.active .from-top, .owl-carousel .owl-item.active .from-bottom {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
}
.owl-carousel .owl-item .from-top, .owl-carousel .owl-item .from-bottom, .owl-carousel .owl-item .from-left, .owl-carousel .owl-item .from-right, .owl-carousel .owl-item .scale-up, .owl-carousel .owl-item .scale-down {
    transition: all .45s .3s ease-in-out;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.owl-carousel .owl-item .from-bottom {
    -webkit-transform: translateY(45px);
    -ms-transform: translateY(45px);
    transform: translateY(45px)
}

.owl-carousel .owl-item .scale-up {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8)
}

.owl-carousel .owl-item.active .scale-up, .owl-carousel .owl-item.active .scale-down {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1
}

.owl-carousel .owl-item .delay-1 {
    transition-delay: .5s
}


@media (min-width: @screen-lg-min) {
    .owl-hero-outer .owl-hero-text{
        //width: 500px;
    }
}

@media (min-width: @screen-md-min) {
    padding-right: 50px;
    padding-left: 50px;

}
