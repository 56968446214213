/****/

.rdb label{
  position: relative;
  cursor: pointer;
  font-size: 15px;

}

.rdb  input[type="checkbox"], .rdb  input[type="radio"]{
  position: absolute;
  right: 9000px;
}

.rdb  input[type="checkbox"], .rdb  input[type="radio"]{
  position: absolute;
  right: 9000px;
}

/*Check box*/
.rdb  input[type="checkbox"] + .label-text:before{
  content: "\f096";
  font-family: "FontAwesome";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing:antialiased;
  width: 1em;
  display: inline-block;
}

.rdb  input[type="checkbox"]:checked + .label-text:before{
  content: "\f14a";
  animation: effect 250ms ease-in;
}

.rdb  input[type="checkbox"]:disabled + .label-text{
  color: #aaa;
}

.rdb  input[type="checkbox"]:disabled + .label-text:before{
  content: "\f0c8";
  color: #ccc;
}

/*Radio box*/

.rdb  input[type="radio"] + .label-text:before{
  content: "\f10c";
  font-family: "FontAwesome";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing:antialiased;
  width: 0.8em;
  display: inline-block;
  margin-right: 5px;
  font-size: 1.1em;
}

.rdb  input[type="radio"]:checked + .label-text:before{
  content: "\f05d";
  animation: effect 250ms ease-in;
}

.rdb  input[type="radio"]:disabled + .label-text{
  color: #aaa;
}

.rdb  input[type="radio"]:disabled + .label-text:before{
  content: "\f10c";
  color: #ccc;
}

/*Radio Toggle*/

.rdb  .toggle input[type="radio"] + .label-text:before{
  content: "\f10c";
  font-family: "FontAwesome";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing:antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 10px;
}

.rdb  .toggle input[type="radio"]:checked + .label-text:before{
  content: "\f05d";
  animation: effect 250ms ease-in;
}

.rdb  .toggle input[type="radio"]:disabled + .label-text{
  color: #aaa;
}

.rdb   .toggle input[type="radio"]:disabled + .label-text:before{
  content: "\f10c";
  color: #ccc;
}


@keyframes effect{
  0%{transform: scale(0);}
  25%{transform: scale(1.3);}
  75%{transform: scale(1.4);}
  100%{transform: scale(1);}
}
