// Base Variables
@weather-icons-path:          "/css/weather";
@WeatherIconVersion:          "1.1.0";
@weather-icons-prefix:         wi;
@weather-icons-font-family:    weathericons;

// Glyph Names
@day-cloudy-gusts:            "\f000";
@day-cloudy-windy:            "\f001";
@day-cloudy:                  "\f002";
@day-fog:                     "\f003";
@day-hail:                    "\f004";
@day-lightning:               "\f005";
@day-rain-mix:                "\f006";
@day-rain-wind:               "\f007";
@day-rain:                    "\f008";
@day-showers:                 "\f009";
@day-snow:                    "\f00a";
@day-sprinkle:                "\f00b";
@day-sunny-overcast:          "\f00c";
@day-sunny:                   "\f00d";
@day-storm-showers:           "\f00e";
@day-thunderstorm:            "\f010";
@cloudy-gusts:                "\f011";
@cloudy-windy:                "\f012";
@cloudy:                      "\f013";
@fog:                         "\f014";
@hail:                        "\f015";
@lightning:                   "\f016";
@rain-mix:                    "\f017";
@rain-wind:                   "\f018";
@rain:                        "\f019";
@showers:                     "\f01a";
@snow:                        "\f01b";
@sprinkle:                    "\f01c";
@storm-showers:               "\f01d";
@thunderstorm:                "\f01e";
@windy:                       "\f021";
@night-alt-cloudy-gusts:      "\f022";
@night-alt-cloudy-windy:      "\f023";
@night-alt-hail:              "\f024";
@night-alt-lightning:         "\f025";
@night-alt-rain-mix:          "\f026";
@night-alt-rain-wind:         "\f027";
@night-alt-rain:              "\f028";
@night-alt-showers:           "\f029";
@night-alt-snow:              "\f02a";
@night-alt-sprinkle:          "\f02b";
@night-alt-storm-showers:     "\f02c";
@night-alt-thunderstorm:      "\f02d";
@night-clear:                 "\f02e";
@night-cloudy-gusts:          "\f02f";
@night-cloudy-windy:          "\f030";
@night-cloudy:                "\f031";
@night-hail:                  "\f032";
@night-lightning:             "\f033";
@night-rain-mix:              "\f034";
@night-rain-wind:             "\f035";
@night-rain:                  "\f036";
@night-showers:               "\f037";
@night-snow:                  "\f038";
@night-sprinkle:              "\f039";
@night-storm-showers:         "\f03a";
@night-thunderstorm:          "\f03b";
@celsius:                     "\f03c";
@cloud-down:                  "\f03d";
@cloud-refresh:               "\f03e";
@cloud-up:                    "\f040";
@cloud:                       "\f041";
@degrees:                     "\f042";
@down-left:                   "\f043";
@down:                        "\f044";
@fahrenheit:                  "\f045";
@horizon-alt:                 "\f046";
@horizon:                     "\f047";
@left:                        "\f048";
@_reserved_:                  "\f049";
@night-fog:                   "\f04a";
@refresh-alt:                 "\f04b";
@refresh:                     "\f04c";
@right:                       "\f04d";
@sprinkles:                   "\f04e";
@strong-wind:                 "\f050";
@sunrise:                     "\f051";
@sunset:                      "\f052";
@thermometer-exterior:        "\f053";
@thermometer-internal:        "\f054";
@thermometer:                 "\f055";
@tornado:                     "\f056";
@up-right:                    "\f057";
@up:                          "\f058";
@wind-west:                   "\f059";
@wind-south-west:             "\f05a";
@wind-south-east:             "\f05b";
@wind-south:                  "\f05c";
@wind-north-west:             "\f05d";
@wind-north-east:             "\f05e";
@wind-north:                  "\f060";
@wind-east:                   "\f061";

// New v1.2
@smoke:                       "\f062";
@dust:                        "\f063";
@snow-wind:                   "\f064";
@day-snow-wind:               "\f065";
@night-snow-wind:             "\f066";
@night-alt-snow-wind:         "\f067";
@day-sleet-storm:             "\f068";
@night-sleet-storm:           "\f069";
@night-alt-sleet-storm:       "\f06a";
@day-snow-thunderstorm:       "\f06b";
@night-snow-thunderstorm:     "\f06c";
@night-alt-snow-thunderstorm: "\f06d";
@solar-eclipse:               "\f06e";
@lunar-eclipse:               "\f070";
@meteor:                      "\f071";
@hot:                         "\f072";
@hurricane:                   "\f073";
@smog:                        "\f074";
@alien:                       "\f075";
@snowflake-cold:              "\f076";
@stars:                       "\f077";

// Moon Phases
@moon-full:                   "\f078";
@moon-waxing-gibbous:         "\f079";
@moon-waxing-quarter:         "\f07a";
@moon-waxing-crescent:        "\f07b";
@moon-young:                  "\f07c";
@moon-new:                    "\f07d";
@moon-old:                    "\f07e";
@moon-waning-crescent:        "\f080";
@moon-waning-quarter:         "\f081";
@moon-waning-gibbous:         "\f082";

// More new v1.2
@night-partly-cloudy:         "\f083";