// Nav headers (for dropdowns and lists)
.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: @line-height-small;
  color: @gray-light;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  text-transform: uppercase;
}
// Space them out when they follow another list item (link)
.nav li + .nav-header {
  margin-top: 9px;
}

// NAV LIST
// --------

.nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.nav-list > li > a,
.nav-list .nav-header {
  margin-left:  -15px;
  margin-right: -15px;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
}
.nav-list > li > a {
  padding: 5px 15px;
}
.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
  color: @body-bg;
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  background-color: @link-color;
}
.nav-list [class^="icon-"],
.nav-list [class*=" icon-"] {
  margin-right: 2px;
}
// Dividers (basically an hr) within the dropdown
.nav-list .divider {
  .nav-divider();
}