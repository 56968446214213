.nav-section {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.nav-section li {
  position: relative;
  padding: 4px 15px;

}

.nav-section li a {
  color: black;
  text-decoration: underline;
}

.nav-section li a:hover {
  text-decoration: none;
}

.nav-section li::before {
  background: #bcbcbc none repeat scroll 0 0;
  border: 1px solid #888888;
  border-radius: 1px;
  content: "";
  display: inline-block;
  height: 6px;
  left: 0;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  top: 0.7em;
  width: 6px;
}
