

.breadcrumb {
	margin-bottom: 20px;
	padding: 4px ;
	//font-size: 13px;
	//line-height: 1.1;
	background-color:transparent;
}

.breadcrumb ol{
	margin: 0px;
	padding-left: 10px;
}

.breadcrumb, .breadcrumb a{
	color: @my-link-color;
}


.breadcrumb > li + li::before {
	font-family: FontAwesome;
	content: "\f0da";
	color: @my-brand-primary;
}
