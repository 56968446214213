.non-responsive-margin( @size, @size-px) {

		.margin-left-@{size}{
        margin-left: @size-px;
		}

		.margin-right-@{size}{
      margin-right: @size-px;
		}

		.margin-top-@{size}{
      margin-top: @size-px;
		}

		.margin-bottom-@{size}{
      margin-bottom: @size-px;
		}

}




.responsive-margin(@class, @size, @size-px) {

		.margin-left-@{class}-@{size}{
        margin-left: @size-px;
		}

		.margin-right-@{class}-@{size}{
      margin-right: @size-px;
		}

		.margin-top-@{class}-@{size}{
      margin-top: @size-px;
		}

		.margin-bottom-@{class}-@{size}{
      margin-bottom: @size-px;
		}

}

@media (min-width: @screen-lg-min) {
	.responsive-margin(lg,0,0px);
	//.responsive-margin(lg,5,5px);
	.responsive-margin(lg,10,10px);
	//.responsive-margin(lg,15,15px);
	.responsive-margin(lg,20,20px);
	.responsive-margin(lg,40,40px);

}

@media (min-width: @screen-md-min) {
	.responsive-margin(md,0,0px);
	//.responsive-margin(md,5,5px);
	.responsive-margin(md,10,10px);
	//.responsive-margin(md,15,15px);
	.responsive-margin(md,20,20px);
	.responsive-margin(md,40,40px);

}

@media (min-width: @screen-sm-min) {
	.responsive-margin(sm,0,0px);
	//.responsive-margin(sm,5,5px);
	.responsive-margin(sm,10,10px);
	//.responsive-margin(sm,15,15px);
	.responsive-margin(sm,20,20px);
	.responsive-margin(sm,40,40px);

}


@media (max-width: @screen-xs-min) {
	.responsive-margin(xs,0,0px);
	.responsive-margin(xs,5,5px);
	.responsive-margin(xs,10,10px);
	.responsive-margin(xs,15,15px);
	.responsive-margin(xs,20,20px);
}

.non-responsive-margin(10,10px);
.non-responsive-margin(20,20px);
.non-responsive-margin(40,40px);
.non-responsive-margin(60,60px);
.non-responsive-margin(70,70px);
.non-responsive-margin(80,80px);





