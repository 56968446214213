
.define-if-not-defined() {

  @btn-section-color: #333;
  @btn-section-bg: #f5f5f5;

  @btn-section-2: #ffecb3;
  @btn-section-3: #c8e6c9;
  @btn-section-4: #e3f2f6;
  @btn-section-5: #ffebee;



}




.btn.btn-section:hover{
  background-color: @btn-section-bg;
  color: @btn-section-color;
}


.btn.btn-section a{
  text-decoration: none;
}

.btn.btn-section{
  font-size: 13px;
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  position: relative;
  z-index: 1;
  background-color: @btn-section-bg;
  color: @btn-section-color;
  width:100%;
  margin-bottom: 20px;
  white-space:normal;
  vertical-align: middle;


}

.btn.btn-section.width-auto{
  width: auto;
}


.btn.btn-section::after {
  background-color: darken( @btn-section-bg, 5%);
  border-radius: 5px;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: width 0.35s ease;
  transition: width 0.35s ease;
  width: 0;
  z-index: -1;
}

.btn.btn-section:hover::after
{
  width: 100%
}


.btn.btn-section-primary:hover{
  background-color: @btn-section-2;
  color: @btn-section-color;
}
.btn.btn-section-primary{
  background-color: @btn-section-2;
}
.btn.btn-section-primary::after {
  background-color: darken( @btn-section-2, 5%);
}


.block-odkazy .b-item{
  padding: 10px;
}


.btn-section .b-inner img{

  float: left;
  margin-right: 12px;

}

.btn.btn-section .b-inner .b-title{
  color: @btn-section-color;
  font-weight: bold;
}

.btn.btn-section .b-inner .b-desc{
  font-size: 11px;
  color: lighten( @btn-section-color,5%);
  font-weight: normal;
}


.btn-2 .btn-section{
  background-color: @btn-section-2;
}
.btn-2 .btn-section::after {
  background-color: darken( @btn-section-2, 5%);
}

.btn-2 .btn.btn-section:hover{
  background-color: @btn-section-2;
}


.btn-3 .btn-section{
  background-color: @btn-section-3;
}
.btn-3 .btn-section::after {
  background-color: darken( @btn-section-3, 5%);
}

.btn-3 .btn.btn-section:hover{
  background-color: @btn-section-3;
}


.btn-4 .btn-section{
  background-color: @btn-section-4;
}
.btn-4 .btn-section::after {
  background-color: darken( @btn-section-4, 5%);
}

.btn-4 .btn.btn-section:hover{
  background-color: @btn-section-4;
}


.btn-5 .btn-section{
  background-color: @btn-section-5;
}
.btn-5 .btn-section::after {
  background-color: darken( @btn-section-5, 5%);
}

.btn-5 .btn.btn-section:hover{
  background-color: @btn-section-5;
}

