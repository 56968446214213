
@wrapperPadding : 0;



@gridRowWidth1200Wrapper:        (@container-lg) + @wrapperPadding;
@media (min-width: 1200px) {
	.container.wrapper {
		width: @gridRowWidth1200Wrapper;
	}

}

.section-olo{
	h2{
		background-color: transparent;
		color: #333;
		padding: 0px;
		text-transform: uppercase	;
		font-size: 25px;
		margin-bottom: 0px;
	}
	.naturpack{
		margin-top: 20px;
		margin-left: 20px;
		margin-bottom: 20px;
	}
	padding-bottom: 50px;
}