@font-face {
    font-family: '@{weather-icons-font-family}';
    src: url('@{weather-icons-path}/@{weather-icons-font-family}-regular-webfont.eot');
    src: url('@{weather-icons-path}/@{weather-icons-font-family}-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{weather-icons-path}/@{weather-icons-font-family}-regular-webfont.woff') format('woff'),
         url('@{weather-icons-path}/@{weather-icons-font-family}-regular-webfont.ttf') format('truetype'),
         url('@{weather-icons-path}/@{weather-icons-font-family}-regular-webfont.svg#weathericons-regular-webfontRg') format('svg');
    font-weight: normal;
    font-style: normal;
}

.@{weather-icons-prefix} {
  display: inline-block;
  font-family: '@{weather-icons-font-family}';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Day
.@{weather-icons-prefix}-day-cloudy-gusts:before               { content: @day-cloudy-gusts            ; }
.@{weather-icons-prefix}-day-cloudy-windy:before               { content: @day-cloudy-windy            ; }
.@{weather-icons-prefix}-day-cloudy:before                     { content: @day-cloudy                  ; }
.@{weather-icons-prefix}-day-fog:before                        { content: @day-fog                     ; }
.@{weather-icons-prefix}-day-hail:before                       { content: @day-hail                    ; }
.@{weather-icons-prefix}-day-lightning:before                  { content: @day-lightning               ; }
.@{weather-icons-prefix}-day-rain-mix:before                   { content: @day-rain-mix                ; }
.@{weather-icons-prefix}-day-rain-wind:before                  { content: @day-rain-wind               ; }
.@{weather-icons-prefix}-day-rain:before                       { content: @day-rain                    ; }
.@{weather-icons-prefix}-day-showers:before                    { content: @day-showers                 ; }
.@{weather-icons-prefix}-day-snow:before                       { content: @day-snow                    ; }
.@{weather-icons-prefix}-day-sprinkle:before                   { content: @day-sprinkle                ; }
.@{weather-icons-prefix}-day-sunny-overcast:before             { content: @day-sunny-overcast          ; }
.@{weather-icons-prefix}-day-sunny:before                      { content: @day-sunny                   ; }
.@{weather-icons-prefix}-day-storm-showers:before              { content: @day-storm-showers           ; }
.@{weather-icons-prefix}-day-thunderstorm:before               { content: @day-thunderstorm            ; }
// Neutral- 
.@{weather-icons-prefix}-cloudy-gusts:before                   { content: @cloudy-gusts                ; }
.@{weather-icons-prefix}-cloudy-windy:before                   { content: @cloudy-windy                ; }
.@{weather-icons-prefix}-cloudy:before                         { content: @cloudy                      ; }
.@{weather-icons-prefix}-fog:before                            { content: @fog                         ; }
.@{weather-icons-prefix}-hail:before                           { content: @hail                        ; }
.@{weather-icons-prefix}-lightning:before                      { content: @lightning                   ; }
.@{weather-icons-prefix}-rain-mix:before                       { content: @rain-mix                    ; }
.@{weather-icons-prefix}-rain-wind:before                      { content: @rain-wind                   ; }
.@{weather-icons-prefix}-rain:before                           { content: @rain                        ; }
.@{weather-icons-prefix}-showers:before                        { content: @showers                     ; }
.@{weather-icons-prefix}-snow:before                           { content: @snow                        ; }
.@{weather-icons-prefix}-sprinkle:before                       { content: @sprinkle                    ; }
.@{weather-icons-prefix}-storm-showers:before                  { content: @storm-showers               ; }
.@{weather-icons-prefix}-thunderstorm:before                   { content: @thunderstorm                ; }
.@{weather-icons-prefix}-windy:before                          { content: @windy                       ; }
// Night 
.@{weather-icons-prefix}-night-alt-cloudy-gusts:before         { content: @night-alt-cloudy-gusts      ; }
.@{weather-icons-prefix}-night-alt-cloudy-windy:before         { content: @night-alt-cloudy-windy      ; }
.@{weather-icons-prefix}-night-alt-hail:before                 { content: @night-alt-hail              ; }
.@{weather-icons-prefix}-night-alt-lightning:before            { content: @night-alt-lightning         ; }
.@{weather-icons-prefix}-night-alt-rain-mix:before             { content: @night-alt-rain-mix          ; }
.@{weather-icons-prefix}-night-alt-rain-wind:before            { content: @night-alt-rain-wind         ; }
.@{weather-icons-prefix}-night-alt-rain:before                 { content: @night-alt-rain              ; }
.@{weather-icons-prefix}-night-alt-showers:before              { content: @night-alt-showers           ; }
.@{weather-icons-prefix}-night-alt-snow:before                 { content: @night-alt-snow              ; }
.@{weather-icons-prefix}-night-alt-sprinkle:before             { content: @night-alt-sprinkle          ; }
.@{weather-icons-prefix}-night-alt-storm-showers:before        { content: @night-alt-storm-showers     ; }
.@{weather-icons-prefix}-night-alt-thunderstorm:before         { content: @night-alt-thunderstorm      ; }
.@{weather-icons-prefix}-night-clear:before                    { content: @night-clear                 ; }
.@{weather-icons-prefix}-night-cloudy-gusts:before             { content: @night-cloudy-gusts          ; }
.@{weather-icons-prefix}-night-cloudy-windy:before             { content: @night-cloudy-windy          ; }
.@{weather-icons-prefix}-night-cloudy:before                   { content: @night-cloudy                ; }
.@{weather-icons-prefix}-night-hail:before                     { content: @night-hail                  ; }
.@{weather-icons-prefix}-night-lightning:before                { content: @night-lightning             ; }
.@{weather-icons-prefix}-night-rain-mix:before                 { content: @night-rain-mix              ; }
.@{weather-icons-prefix}-night-rain-wind:before                { content: @night-rain-wind             ; }
.@{weather-icons-prefix}-night-rain:before                     { content: @night-rain                  ; }
.@{weather-icons-prefix}-night-showers:before                  { content: @night-showers               ; }
.@{weather-icons-prefix}-night-snow:before                     { content: @night-snow                  ; }
.@{weather-icons-prefix}-night-sprinkle:before                 { content: @night-sprinkle              ; }
.@{weather-icons-prefix}-night-storm-showers:before            { content: @night-storm-showers         ; }
.@{weather-icons-prefix}-night-thunderstorm:before             { content: @night-thunderstorm          ; }
// Misc 
.@{weather-icons-prefix}-celsius:before                        { content: @celsius                     ; }
.@{weather-icons-prefix}-cloud-down:before                     { content: @cloud-down                  ; }
.@{weather-icons-prefix}-cloud-refresh:before                  { content: @cloud-refresh               ; }
.@{weather-icons-prefix}-cloud-up:before                       { content: @cloud-up                    ; }
.@{weather-icons-prefix}-cloud:before                          { content: @cloud                       ; }
.@{weather-icons-prefix}-degrees:before                        { content: @degrees                     ; }
.@{weather-icons-prefix}-down-left:before                      { content: @down-left                   ; }
.@{weather-icons-prefix}-down:before                           { content: @down                        ; }
.@{weather-icons-prefix}-fahrenheit:before                     { content: @fahrenheit                  ; }
.@{weather-icons-prefix}-horizon-alt:before                    { content: @horizon-alt                 ; }
.@{weather-icons-prefix}-horizon:before                        { content: @horizon                     ; }
.@{weather-icons-prefix}-left:before                           { content: @left                        ; }
.@{weather-icons-prefix}-lightning:before                      { content: @lightning                   ; }
.@{weather-icons-prefix}-night-fog:before                      { content: @night-fog                   ; }
.@{weather-icons-prefix}-refresh-alt:before                    { content: @refresh-alt                 ; }
.@{weather-icons-prefix}-refresh:before                        { content: @refresh                     ; }
.@{weather-icons-prefix}-right:before                          { content: @right                       ; }
.@{weather-icons-prefix}-sprinkles:before                      { content: @sprinkles                   ; }
.@{weather-icons-prefix}-strong-wind:before                    { content: @strong-wind                 ; }
.@{weather-icons-prefix}-sunrise:before                        { content: @sunrise                     ; }
.@{weather-icons-prefix}-sunset:before                         { content: @sunset                      ; }
.@{weather-icons-prefix}-thermometer-exterior:before           { content: @thermometer-exterior        ; }
.@{weather-icons-prefix}-thermometer-internal:before           { content: @thermometer-internal        ; }
.@{weather-icons-prefix}-thermometer:before                    { content: @thermometer                 ; }
.@{weather-icons-prefix}-tornado:before                        { content: @tornado                     ; }
.@{weather-icons-prefix}-up-right:before                       { content: @up-right                    ; }
.@{weather-icons-prefix}-up:before                             { content: @up                          ; }
.@{weather-icons-prefix}-wind-west:before                      { content: @wind-west                   ; }
.@{weather-icons-prefix}-wind-south-west:before                { content: @wind-south-west             ; }
.@{weather-icons-prefix}-wind-south-east:before                { content: @wind-south-east             ; }
.@{weather-icons-prefix}-wind-south:before                     { content: @wind-south                  ; }
.@{weather-icons-prefix}-wind-north-west:before                { content: @wind-north-west             ; }
.@{weather-icons-prefix}-wind-north-east:before                { content: @wind-north-east             ; }
.@{weather-icons-prefix}-wind-north:before                     { content: @wind-north                  ; }
.@{weather-icons-prefix}-wind-east:before                      { content: @wind-east                   ; }
// New v1.1
.@{weather-icons-prefix}-smoke:before                          { content: @smoke                       ; }
.@{weather-icons-prefix}-dust:before                           { content: @dust                        ; }
.@{weather-icons-prefix}-snow-wind:before                      { content: @snow-wind                   ; }
.@{weather-icons-prefix}-day-snow-wind:before                  { content: @day-snow-wind               ; }
.@{weather-icons-prefix}-night-snow-wind:before                { content: @night-snow-wind             ; }
.@{weather-icons-prefix}-night-alt-snow-wind:before            { content: @night-alt-snow-wind         ; }
.@{weather-icons-prefix}-day-sleet-storm:before                { content: @day-sleet-storm             ; }
.@{weather-icons-prefix}-night-sleet-storm:before              { content: @night-sleet-storm           ; }
.@{weather-icons-prefix}-night-alt-sleet-storm:before          { content: @night-alt-sleet-storm       ; }
.@{weather-icons-prefix}-day-snow-thunderstorm:before          { content: @day-snow-thunderstorm       ; }
.@{weather-icons-prefix}-night-snow-thunderstorm:before        { content: @night-snow-thunderstorm     ; }
.@{weather-icons-prefix}-night-alt-snow-thunderstorm:before    { content: @night-alt-snow-thunderstorm ; }
.@{weather-icons-prefix}-solar-eclipse:before                  { content: @solar-eclipse               ; }
.@{weather-icons-prefix}-lunar-eclipse:before                  { content: @lunar-eclipse               ; }
.@{weather-icons-prefix}-meteor:before                         { content: @meteor                      ; }
.@{weather-icons-prefix}-hot:before                            { content: @hot                         ; }
.@{weather-icons-prefix}-hurricane:before                      { content: @hurricane                   ; }
.@{weather-icons-prefix}-smog:before                           { content: @smog                        ; }
.@{weather-icons-prefix}-alien:before                          { content: @alien                       ; }
.@{weather-icons-prefix}-snowflake-cold:before                 { content: @snowflake-cold              ; }
.@{weather-icons-prefix}-stars:before                          { content: @stars                       ; }
// Moon phases, v1.1
.@{weather-icons-prefix}-moon-full:before                      { content: @moon-full                   ; }
.@{weather-icons-prefix}-moon-waxing-gibbous:before            { content: @moon-waxing-gibbous         ; }
.@{weather-icons-prefix}-moon-waxing-quarter:before            { content: @moon-waxing-quarter         ; }
.@{weather-icons-prefix}-moon-waxing-crescent:before           { content: @moon-waxing-crescent        ; }
.@{weather-icons-prefix}-moon-young:before                     { content: @moon-young                  ; }
.@{weather-icons-prefix}-moon-new:before                       { content: @moon-new                    ; }
.@{weather-icons-prefix}-moon-old:before                       { content: @moon-old                    ; }
.@{weather-icons-prefix}-moon-waning-crescent:before           { content: @moon-waning-crescent        ; }
.@{weather-icons-prefix}-moon-waning-quarter:before            { content: @moon-waning-quarter         ; }
.@{weather-icons-prefix}-moon-waning-gibbous:before            { content: @moon-waning-gibbous         ; }

.@{weather-icons-prefix}-night-partly-cloudy:before            { content: @night-partly-cloudy         ; }

